import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux"
import axios from 'axios'
import { Link } from 'react-router-dom'

import { LoaderContext } from "context/LoaderContext"
import Pagination from 'core/Pagination';

const ProductList = () => {

    const [allProducts, setAllProducts] = useState(null)
    const { loading, setLoading } = React.useContext(LoaderContext);
    const [searchValue, setSearchValue] = useState("")
    const { token } = useSelector(state => state.auth)

    useEffect(() => {
        if (token != null && token != "") {
            // console.log(token)
            setLoading(true);
            axios.get("/products", {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': token
                }
            }).then(response => {
                setAllProducts(response.data)
                console.log(response.data)

                setLoading(false);
            })
                .catch(errors => {
                    console.log(errors)
                })
        }
    }, [token])

    const updatePage = (url) => {
        setLoading(true);
        axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then(response => {
            setAllProducts(response.data)
            setLoading(false);
        })
    }

    return (
        <>
            <div className="px-8 mt-8 mb-8">
                <div className="page-heading">
                    <h1 className="pageHeading">All Products</h1>
                    <div className="flex">
                        <Link to="/admin/products-adding" className="button button-outline-primary w-48">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                            </svg>
                            <span className="ml-2 buttonText">Add a New Product</span>
                        </Link>
                    </div>
                </div>
                <div className="card">
                    <div className="border-b">
                        <div className="card-header">
                            <div>
                                <h4 className="pageHeading">Products</h4>
                            </div>
                            <input className="inputBox" placeholder="Search" onChange={e => setSearchValue(e.target.value)} />
                        </div>
                    </div>

                    <div className="card-body overflow-x-auto">
                        <table className="w-350 2xl:w-full table-fixed">

                            <thead>
                                <tr className="border-b h-12">
                                    <th className="tableHeader w-0.7/9">Id</th>
                                    <th className="tableHeader w-2/9">Name</th>
                                    <th className="tableHeader w-1/9">Price</th>
                                    <th className="tableHeader w-1/9">Status</th>
                                    <th className="tableHeader w-1/9">Store Name</th>
                                    <th className="tableHeader w-1/9">Stock</th>
                                    <th className="tableHeader w-1/9">Category</th>

                                    <th className="tableHeader w-0.5/9 text-center">Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {allProducts?.data?.map((item, index) => (
                                    <tr className="border-b py-4 h-20" key={index}>
                                        <td>
                                            <p className="tableData">{item.id}</p>
                                        </td>
                                        <td>
                                            <div className="flex items-center">
                                                {(item.thumbnail) ? (
                                                    <>
                                                        <img className="w-10 h-auto" alt="Product"
                                                            src={item.thumbnail}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="w-10 h-10 bg-gray-200 rounded-full"></span>
                                                    </>
                                                )}

                                                <div className="ml-4">
                                                    <h3 className="tableData">{item.name}</h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p className="tableData"><b>Price:</b> {item.price}</p>
                                            <p className="tableData"><b>Cost Price:</b> {item.cost_price}</p>
                                            <p className="tableData"><b>Special Price:</b> {item.special_price}</p>
                                        </td>
                                        <td>
                                            <p className="tableData uppercase">{item.status}</p>
                                        </td>
                                        <td>
                                            <p className="tableData">{item.store.name}</p>
                                        </td>
                                        <td>
                                            {
                                                item.stock ? <p className="tableData">{item.stock}</p> : <p className="tableData">In Stock</p>
                                            }
                                        </td>
                                        <td>
                                            <p className="tableData"><b>Product Category:</b> {item.product_category.name}</p>
                                            <p className="tableData"><b>Sub Category:</b> {item.product_category.sub_category.name}</p>
                                            <p className="tableData"><b>Main Category:</b> {item.product_category.sub_category.category.name}</p>
                                        </td>

                                        <td className="text-center">

                                            <i className="fas fa-pen"></i>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="card-footer">
                        <div className="flex flex-col justify-between md:flex-row items-center w-full">
                            {allProducts && <p className="font-Poppins font-normal text-sm">Showing <b>{allProducts.from}-{allProducts.to}</b> from <b>{allProducts.total}</b> data</p>}
                            {/* <p>Showing <b>{supplierData.from}-{supplierData.to}</b> from <b>{supplierData.total}</b> data</p> */}

                            <div className="flex items-center">
                                {allProducts && <Pagination sellers={allProducts} setUpdate={updatePage} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductList
