import axios from 'axios';
import Pagination from 'core/Pagination';
import React from 'react'
import { useSelector } from 'react-redux';

export default function Customers() {

    const [customers, setCustomers] = React.useState([]);
    const { token } = useSelector(state => state.auth)

    const fetchCustomers = () => {
        axios.get('/customers', {
            headers: {
                Authorization: token
            }
        }).then(response => {
            console.log(response);
            setCustomers(response.data);
        }).catch(errors => {
            console.log(errors.response);
        })
    }

    const updatePage = (url) => {
        // setLoading(true);
        axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then(response => {
            setCustomers(response.data)
            // setLoading(false);
        })
    }

    React.useEffect(() => {
        if(token != '')
        {
            fetchCustomers();
        }
    }, [token]);

    return (
        <>
            <div className="px-8 mt-8 mb-8">
                <div className="page-heading">
                    <h1 className="pageHeading">All Customer</h1>
                    <div className="flex">
                        
                    </div>
                </div>

                <div className="card">
                    <div className="border-b">
                        <div className="card-header">
                            <div>
                                <h4 className="pageHeading">Customer List</h4>
                            </div>
                            {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                            {/* <input className="inputBox" placeholder="Search" /> */}
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="w-full">
                            <thead>
                                <tr className="border-b h-12">
                                    <th className="tableHeader">Mobile</th>
                                    <th className="tableHeader">Email</th>
                                    <th className="tableHeader">Status</th>
                                    <th className="tableHeader">Total Orders</th>
                                    {/* <th className="tableHeader">#</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {customers?.data?.map((item, index) => (
                                    <tr key={index} className="border-b py-4 h-20">
                                        <td className="tableData"><p>{item.phone}</p></td>
                                        <td className="tableData"><p>{item.email}</p></td>
                                        <td className="tableData"><p>{(item.status) ? 'Active' : 'Disabled'}</p></td>
                                        <td className="tableData"><p>{item.orders_count}</p></td>
                                        {/* <td className="tableData"><i class="fas fa-edit"></i></td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="card-footer">
                        <div className="flex flex-col justify-between md:flex-row items-center w-full">
                            {customers && <p className="font-Poppins font-normal text-sm">Showing <b>{customers.from} - {customers.to}</b> from <b>{customers.total}</b> data</p>}

                            <div className="flex items-center">
                                {customers && <Pagination sellers={customers} setUpdate={updatePage} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
