import axios from 'axios'
import Pagination from 'core/Pagination'
import React from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

export default function AllOrder() {

    
    let {status} = useParams();

    if(status == undefined)
    {
        console.log('Its not');
    }

    const { token } = useSelector(state => state.auth)

    const [orders, setOrders] = React.useState([]);
    const [search, setSearch] = React.useState('');

    const fetchAllOrder = () => {
        if(status == undefined)
        {
            axios.get('/manage-orders', {
                headers: {
                    Authorization: token
                }
            }).then(response => {
                console.log(response);
                setOrders(response.data)
            }).catch(errors => {
                console.log(errors.response);
            })
        }else{
            axios.get(`/manage-orders?status=${status}`, {
                headers: {
                    Authorization: token
                }
            }).then(response => {
                console.log(response);
                setOrders(response.data)
            }).catch(errors => {
                console.log(errors.response);
            })
        }
    }

    const updatePage = (url) => {
        // setLoading(true);
        axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then(response => {
            setOrders(response.data)
            // setLoading(false);
        })
    }

    React.useEffect(() => {
        if(token != '')
        {
            fetchAllOrder();
        }
    }, [token]);

    React.useEffect(() => {
        if(token != '')
        {
            fetchAllOrder();
        }
    }, [status])

    React.useEffect(() => {
        if(search.length >= 3 )
        {
            axios.get(`/manage-orders/${search}/search`, {
                headers: {
                    Authorization: token
                }
            }).then(response => {
                // console.log(response);
                setOrders(response.data);
            }).catch(errors => {
                console.log(errors.response);
            })
        }else{
            fetchAllOrder();
        }
    }, [search])

    return (
        <>
            <div className="px-8 mt-8 mb-8">
                <div className="page-heading">
                    <h1 className="pageHeading">All Orders</h1>
                    <div className="flex">
                        
                    </div>
                </div>
                <div className="card">
                    <div className="border-b">
                        <div className="card-header">
                            <div>
                                <h4 className="pageHeading">Orders</h4>
                            </div>
                            {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                            <input className="inputBox" onChange={(e) => setSearch(e.target.value)} value={search} placeholder="Order Number, Customer Name, Phone Number" />
                        </div>
                    </div>
                    <div className="card-body overflow-x-auto">
                        <table className="w-350 2xl:w-full table-fixed">
                            <thead>
                                <tr className="border-b h-12">
                                    <th className="tableHeader">Order Number</th>
                                    <th className="tableHeader">Customer Name/Phone</th>
                                    <th className="tableHeader">Order Date</th>
                                    <th className="tableHeader">Last Update</th>
                                    <th className="tableHeader">Status</th>
                                    <th className="tableHeader text-center">Payment Status</th>
                                    <th className="tableHeader">Order Amount</th>
                                    <th className="tableHeader">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders?.data?.map((item, index) => (    
                                    <tr key={index} className="border-b py-4 h-20">
                                        <td><p className="tableData uppercase"><span className="font-bold">{item.order_number}</span></p></td>
                                        <td>
                                            <p className="tableData">{item?.customer?.name}</p>
                                            <p className="tableData">{item?.customer?.phone}</p>
                                        </td>
                                        <td><p className="tableData"><Moment format="D MMM YYYY">{item.created_at}</Moment></p></td>
                                        <td><p className="tableData"><Moment format="D MMM YYYY">{item.updated_at}</Moment></p></td>
                                        <td><p className="tableData">{item.status}</p></td>
                                        <td><p className="tableData uppercase text-center"><span className="bg-gray-200 p-1 rounded-full">{item.set_paid == 1 ? 'Paid' : 'Unpaid'}</span></p></td>
                                        <td><p className="tableData">TK. {item.total}</p></td>
                                        <td>
                                            <Link to={`/admin/orders/${item.id}/details`}><i class="fas fa-edit"></i></Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="card-footer">
                        <div className="flex flex-col justify-between md:flex-row items-center w-full">
                            {orders && <p className="font-Poppins font-normal text-sm">Showing <b>{orders.from} - {orders.to}</b> from <b>{orders.total}</b> data</p>}

                            <div className="flex items-center">
                                {orders && <Pagination sellers={orders} setUpdate={updatePage} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
