import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

// components

import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

// views

import Dashboard from "views/admin/Dashboard.js";
// import Maps from "../views/admin/Maps.js";
import Settings from "../views/admin/Settings.js";
import Tables from "views/admin/Tables.js";
import Sellers from "../views/admin/sellers/Sellers.js";

import CreateSeller from "views/admin/sellers/CreateSeller.js";
import Loader from "core/Loader.js";
import { LoaderContext } from "context/LoaderContext.js";

import { StyleProvider } from "context/StyleContext.js";
import EditSeller from "views/admin/sellers/EditSeller.js";

import { useDispatch, useSelector } from "react-redux";
import { setAuth, getToken, setUser } from "redux/auth.js";
import axios from "axios";
import Suppliers from "views/admin/supplier/Suppliers.js";
import Category from "views/admin/category/Category.js";
import SubCategory from "views/admin/category/SubCategory.js";
import ProductCategory from "views/admin/category/ProductCategory.js";

import CreateSupplier from "views/admin/supplier/CreateSupplier.js";
import EditSupplier from "views/admin/supplier/EditSupplier.js";
import BrandList from "views/admin/brand/BrandList.js";
import AttributeList from "views/admin/Attribute/AttributeList.js";
import AttributeValueAddPage from "views/admin/Attribute/AttributeValueAddPage.js";

import Header from "pageLayout/Header.js";
import IndividualPageHeader from "refactoredView/IndividualPageHeader.js";
import NewProductAdding from "views/admin/Products/NewProductAdding.js";
import ProductList from "views/admin/Products/ProductList.js";
import Users from "views/admin/users/Users.js";
import AllOrder from "views/admin/orders/AllOrder.js";
import AddUser from "views/admin/users/AddUser.js";
import EditUser from "views/admin/users/EditUser.js";
import UpdateOrder from "views/admin/orders/UpdateOrder.js";
import Customers from "views/admin/customers/Customers.js";
import ShippingClass from "views/admin/shipping/ShippingClass.js";
import AddCities from "views/admin/shipping/AddCities.js";


export default function Admin() {

  const { auth, token } = useSelector((state) => state.auth)

  let history = useHistory();

  const { loading, setLoading } = React.useContext(LoaderContext);
  const dispatch = useDispatch();

  const [menuCollapse, setMenuCollapse] = useState(true)



  React.useEffect(() => {
    let localToken = localStorage.getItem('token');

    if (localToken === null || localToken === '' || localToken === undefined) {
      setAuth(false);
      history.push('/auth/login');
      console.log('I am fff.');
    } else {
      setLoading(true);
      dispatch(setAuth(true))
      dispatch(getToken())

      if (token !== '') {
        axios.get('/profile', { headers: { Authorization: token } })
          .then(response => {
            dispatch(setUser(response.data))
            setLoading(false);
          })
          .catch(errors => {
            console.log(errors.response?.data);
            setAuth(false);
            history.push('/auth/login');
          })
      }

    }

  }, [token])

  // useEffect(() => {
  //   console.log(menuCollapse)

  // }, [menuCollapse])

  return (
    <div className="h-screen overflow-y-scroll bg-individualPageBG">
      <StyleProvider>
        <Loader loading={loading} />
        <Header menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse} />

        <div className={`${menuCollapse ? "md:ml-20" : "md:ml-67.5"} transition-all duration-200 ease-linear relative overflow-hidden`}>
          <IndividualPageHeader />
          {/* <div className="px-4 w-full h-full"> */}
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />

            <Route path="/admin/sellers" exact component={Sellers} />
            <Route path="/admin/sellers/create" exact component={CreateSeller} />
            <Route path="/admin/suppliers/create" exact component={CreateSupplier} />
            <Route path="/admin/sellers/:id/edit" exact component={EditSeller} />
            <Route path="/admin/suppliers/:id/edit" exact component={EditSupplier} />

            <Route path="/admin/suppliers" exact component={Suppliers} />

            <Route path="/admin/categories" exact component={Category} />
            <Route path="/admin/categories/:id" exact component={SubCategory} />
            <Route path="/admin/categories/sub-category/:id" exact component={ProductCategory} />

            <Route path="/admin/brands" exact component={BrandList} />
            <Route path="/admin/attributes" exact component={AttributeList} />
            <Route path="/admin/attributes/:id" exact component={AttributeValueAddPage} />

            <Route path="/admin/products-adding" exact component={NewProductAdding} />
            <Route path="/admin/all-products" exact component={ProductList} />

            <Route path="/admin/settings" exact component={Settings} />
            <Route path="/admin/tables" exact component={Tables} />
            <Route path="/admin/users" exact component={Users} />
            <Route path="/admin/users/add" exact component={AddUser} />
            <Route path="/admin/users/:id/edit" exact component={EditUser} />
            <Route path="/admin/orders" exact component={AllOrder} />
            <Route path="/admin/orders/:status" exact component={AllOrder} />
            <Route path="/admin/orders/:id/details" exact component={UpdateOrder} />
            <Route path="/admin/customers" exact component={Customers} />
            <Route path="/admin/shipping/class" exact component={ShippingClass} />
            <Route path="/admin/shipping/class/:id/edit" exact component={AddCities} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          {/* </div> */}
        </div>
        {/* </div> */}
      </StyleProvider>
    </div>
  );
}
