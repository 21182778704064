import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "../components/Footers/FooterSmall.js";

// views

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import { AuthContext } from "context/AuthContext.js";

export default function Auth() {

  let history = useHistory();

  let token = localStorage.getItem('token');

  const {setAuth} = React.useContext(AuthContext);

  if(token != null || token !== '' || token !== undefined)
  {
    // history.push('/admin/dashboard');
    // setAuth(true);

    console.log(token);
  }

  return (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage:
                "url(" + require("assets/img/register_bg_2.png").default + ")",
            }}
          ></div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <FooterSmall absolute />
        </section>
      </main>
    </>
  );
}
