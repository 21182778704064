import React, { useState, useEffect } from 'react'
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from 'axios'
import { useSelector } from "react-redux"
import Alert from 'core/Alert'

const UpdateBrandModal = props => {
    const { updateModal, setUpdateModal, brandId, setBrandId } = props

    const [brandName, setBrandName] = useState("")
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState({})
    const { token } = useSelector(state => state.auth);


    const onClose = () => {
        setUpdateModal(prevState => !prevState)
        setStatus({})
        setBrandName("")
        setBrandId(null)
    }

    useEffect(() => {
        console.log(brandName)
    }, [brandName])

    useEffect(() => {
        if (brandId != null) {
            axios.get(`/brands/${brandId}`, {
                headers: {
                    Authorization: token,
                    Accept: 'application/json',
                }
            }).then(response => {
                console.log(response)
                setBrandName(response.data.name)
            }).catch(errors => {
                console.log(errors)
            })
        }
    }, [brandId])

    const handleAddingBrand = () => {
        setLoading(true)
        axios.post(`/brands/${brandId}`,
            { name: brandName, _method: "PUT" },
            {
                headers: {
                    Authorization: token,
                    Accept: 'application/json',
                }
            })
            .then(response => {
                console.log(response)
                setStatus({
                    type: 'SUCCESS',
                    message: response.data.message,
                })
                setBrandName("")
                setLoading(false)
            })
            .catch(errors => {
                setStatus({
                    type: 'DANGER',
                    message: errors.response?.data?.errors?.name[0]
                })
                setLoading(false)
                setBrandName("")
                console.log(errors.response)
            })
    }


    return (
        <Modal open={updateModal} onClose={onClose} blockScroll={false}>
            <div style={{ width: 500 }}>
                <p className="pageHeading mt-4">Enter New Brand Name: </p>
                <input type="text" className="createFromInputField mt-4" onChange={e => setBrandName(e.target.value)} value={brandName} />
                {
                    loading ? (
                        <button className="button button-primary flex items-center mt-4 w-20" disabled style={{ marginLeft: 0 }}> <span className="fas fa-sync-alt mr-2 py-1 animate-spin"></span></button>
                    ) : (
                        <button className="button button-primary mt-4 w-20" style={{ marginLeft: 0 }} onClick={handleAddingBrand}>Update</button>
                    )
                }
                <Alert status={status?.type} type={status?.type} changeStatus={() => setStatus()} message={status?.message} margin="mt-4" />
            </div>
        </Modal>
    )
}

export default UpdateBrandModal
