import axios, {AxiosResponse} from 'axios';
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import Select from 'react-select'
import { notifySuccess, Toast } from 'helper/notify';
import toast from 'react-hot-toast';

export default function UpdateOrder() {

    const { id } = useParams();

    const { token } = useSelector(state => state.auth)
    const [order, setOrder] = React.useState([]);
    const [statuses, setStatuses] = React.useState([]);
    const [status, setStatus] = React.useState('');
    const [orderStatus, setOrderStatus] = React.useState({})
    const [paymentStatus, setPaymentStatus] = React.useState({})
    const [boolPaymentStatus, setBoolPaymentStatus] = React.useState('')

    const fetchOrder = (id) => {
        axios.get(`/manage-orders/${id}`, {headers: {Authorization: token}}).then(response => {
            console.log(response);
            setOrder(response.data);
            setStatus(response.data.status);
            axios.get('/statuses', {headers: {Authorization: token}}).then(response => {
                // console.log(response.data);
                setStatuses([]);
                response.data.map((item) => {
                    setStatuses(prevState => [...prevState, {value: item.name, label: item.name}])
                    // let data = response.data;

                    // let index = data.findIndex(order.status);
                    // console.log(index);
                    setBoolPaymentStatus(response.data.set_paid)
                })
            }).catch(errors => {
                console.log(errors.response);
            })
            
        }).catch(errors => {
            console.log(errors.response);
        })
    }

    React.useEffect(() => {
        if(token != '')
        {
            fetchOrder(id);
        }
    }, [token])

    React.useEffect(() => {
        setOrderStatus({value: status, label: status})
    }, [status])

    React.useEffect(() => {
        if(paymentStatus != null)
        {
            setPaymentStatus({value: boolPaymentStatus, label: boolPaymentStatus ? 'Paid' : 'Unpaid'})
        }
    }, [boolPaymentStatus])

    const updateStatus = (option) => {
        const response = axios.post(`manage-orders/${id}`, {
            _method: 'PUT',
            status: option.value
        }, {
            headers: {
                Authorization: token
            }
        }).then(response => {
            // return true;
        }).catch(error => {
            throw(error)
        })

        return response;
    }

    return (
        <>
            <Toast />
            <div className="px-8 mt-8 mb-8 h-screen overflow-y-auto">
                <div className="page-heading">
                    <h1 className="pageHeading">Order Details of <span className="font-bold uppercase">#{order.order_number}</span></h1>
                    <div className="flex">
                        
                    </div>
                </div>
                <div className="flex -mx-1">

                    <div className="w-1/2 mx-1">
                        <div className="card">
                            <div className="border-b">
                                <div className="card-header">
                                    <div>
                                        <h4 className="pageHeading">Shipping Info</h4>
                                    </div>
                                    {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                                    {/* <input className="inputBox" placeholder="Search" /> */}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="text-sm text-gray-600">
                                    <p className="mb-1"><span className="mr-4 font-bold">Name: </span>{order?.shipping?.name}</p>
                                    <p className="mb-1"><span className="mr-4 font-bold">Phone: </span>{order?.shipping?.phone}</p>
                                    <p className="mb-4"><span className="mr-4 font-bold">Email: </span>{order?.shipping?.email}</p>
                                </div>
                                <div className="mt-2 text-sm">
                                    <p className="text-gray-600 font-bold">Shipping Address</p>
                                    <p>{order?.shipping?.address}</p>
                                    <p>{order?.shipping?.area}, {order?.shipping?.city}, {order?.shipping?.region}</p>
                                </div>
                                <div className="mt-2 text-sm">
                                    <p className="text-gray-600 font-bold">Shipping Type</p>
                                    <p className="uppercase">{order?.shipping_type} Shipping</p>
                                </div>
                            </div>
                        </div>
                        {order.transaction ? (
                            <div className="card mt-2">
                                <div className="border-b">
                                    <div className="card-header">
                                        <div>
                                            <h4 className="pageHeading">Transaction Info</h4>
                                        </div>
                                        {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                                        {/* <input className="inputBox" placeholder="Search" /> */}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="text-sm text-gray-600">
                                        {order.transaction.transaction_id ? (
                                            <p className="mb-1"><span className="mr-4 font-bold">Transaction ID: </span><span className="uppercase">{order?.transaction?.transaction_id}</span></p>
                                        ) : ''}                                        
                                        <p className="mb-1"><span className="mr-4 font-bold">Payment Method: </span><span className="uppercase">{order?.transaction?.method}</span></p>
                                        <p className="mb-4"><span className="mr-4 font-bold">Transaction Status: </span><span className="uppercase">{order?.transaction?.status}</span></p>
                                    </div>
                                </div>
                            </div>
                        ) : ''}
                        
                    </div>
                    
                    <div className="w-1/2 mx-1">
                        <div className="card">
                            <div className="border-b">
                                <div className="card-header">
                                    <div>
                                        <h4 className="pageHeading">Order Status</h4>
                                    </div>
                                    {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                                    {/* <input className="inputBox" placeholder="Search" /> */}
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <label className="text-sm">Select Status</label>
                                    {(order.status == undefined) ? '' : <Select
                                    onChange={option => {setOrderStatus(option); toast.promise(updateStatus(option), {loading: 'Updating..', success: 'Updated', error: 'Something wrong!'})}}
                                     options={statuses}
                                     value={orderStatus}
                                      />}
                                </div>
                            </div>
                        </div>
                        <div className="card  mt-2">
                            <div className="border-b">
                                <div className="card-header">
                                    <div>
                                        <h4 className="pageHeading">Payment Info</h4>
                                    </div>
                                    {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                                    {/* <input className="inputBox" placeholder="Search" /> */}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="text-sm text-gray-600">
                                    <p><span className="font-bold mr-2">Payment Method:</span> {order.payment_method}</p>
                                    <p className="mt-2"><span className="font-bold mr-2">Sub Total:</span> TK. {order.sub_total}</p>
                                    <p className="mt-2"><span className="font-bold mr-2">Shipping Total:</span> TK. {order.shipping_total}</p>
                                    <p className="mt-2"><span className="font-bold mr-2">Discount Total:</span> TK. {order.discount_total}</p>
                                    <p className="mt-2"><span className="font-bold mr-2">Total:</span> TK. {order.total}</p>
                                    <p className="mt-2 mb-2"><span className="font-bold mr-2">Paid:</span> {(order.set_paid == 1) ? 'Paid' : 'Unpaid'}</p>
                                    {order.payment_method == 'cod' ? (
                                        <>
                                        <label className="font-bold">Update Payment Status</label>
                                        <Select
                                            isSearchable={false}
                                            onChange={(option) => [console.log(option), setPaymentStatus(option), notifySuccess()]}
                                            value={paymentStatus}
                                            options={[{value: 0, label: 'Unpaid'}, {value: 1, label: 'Paid'}]}
                                        />
                                        </>
                                    ) : ''}
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="flex -mx-1">
                    <div className="w-full mx-1 mt-4">
                        <div className="card">
                            <div className="border-b">
                                <div className="card-header">
                                    <div>
                                        <h4 className="pageHeading">Ordered Item</h4>
                                    </div>
                                    {/* <input className="inputBox" placeholder="Search" onChange={e => setSearch(e.target.value)} /> */}
                                    {/* <input className="inputBox" placeholder="Search" /> */}
                                </div>
                            </div>
                            <div className="card-body">
                                <table className="table-fixed w-full">
                                    <thead>
                                        <tr className="border-b h-12">
                                            <th className="tableHeader w-8">#</th>
                                            <th className="tableHeader">Item</th>
                                            <th className="tableHeader">Qty</th>
                                            <th className="tableHeader">Unit Price</th>
                                            <th className="tableHeader">Line Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {order?.items?.map((item, index) => (
                                            <tr key={index} className="border-b py-4 h-20">
                                                <td><p className="tableData"><span className="font-bold">{index+1}</span></p></td>
                                                <td><p className="tableData">{item.product.name}</p></td>
                                                <td><p className="tableData">{item.quantity}</p></td>
                                                <td><p className="tableData">TK. {item.unit_price}</p></td>
                                                <td><p className="tableData">TK. {item.line_total}</p></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
