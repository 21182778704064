//import useState hook to create menu collapse state
import React, { useState } from "react";

//import react pro sidebar components
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
    SubMenu
} from "react-pro-sidebar";

//import icons from react icons
import { FaList, FaRegHeart } from "react-icons/fa";
import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";
import { RiPencilLine } from "react-icons/ri";
import { GrDashboard } from "react-icons/gr"
import { MdOutlineSummarize } from "react-icons/md"
import { BsPersonCheck, BsPersonPlus } from "react-icons/bs"
import { SiBrandfolder } from "react-icons/si"
import { RiListSettingsFill } from "react-icons/ri"

//import sidebar css from react-pro-sidebar module and our custom css 
import "react-pro-sidebar/dist/css/styles.css";
import "./Header.css";
import { Link } from "react-router-dom";

import CompanyLogo from "../assets/img/favicon.png"

import { activeNav } from "navhelper";


const Header = props => {

    //create initial menuCollapse state using useState hook
    const { menuCollapse, setMenuCollapse } = props

    //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    return (
        <>
            <div
                id="header"
            >
                {/* collapsed props to change menu size using menucollapse state */}
                <ProSidebar collapsed={menuCollapse}>
                    <SidebarHeader>
                        <div className="px-6 py-4 flex items-center">
                            {menuCollapse === false ? <div className="flex items-center">
                                <div onClick={menuIconClick} className="w-10 h-10">
                                    <img src={CompanyLogo} alt="company Name" className="w-full h-full" />
                                </div>
                                <div className="ml-4">
                                    <p className="font-Poppins font-bold text-xl truncate">Fashion Field</p>
                                </div>
                            </div> : <div onClick={menuIconClick} className="w-10 h-10">
                                <img src={CompanyLogo} alt="company Name" className="w-full h-full" />
                            </div>}
                        </div>
                    </SidebarHeader>
                    <SidebarContent>
                        <Menu iconShape="circle">
                            <MenuItem icon={<MdOutlineSummarize />} active={activeNav("/admin/dashboard")}>
                                <Link to="/admin/dashboard" className={`${activeNav("/admin/dashboard") ? "font-Poppins font-bold" : "font-Poppins"}`}>Dashbord</Link>
                            </MenuItem>
                            <MenuItem icon={<BsPersonCheck />} active={window.location.href.indexOf("/admin/sellers") !== -1 ? true : false}>
                                <Link to="/admin/sellers" className={`${window.location.href.indexOf("/admin/sellers") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Seller</Link>
                            </MenuItem>
                            <MenuItem icon={<BsPersonPlus />} active={window.location.href.indexOf("/admin/suppliers") !== -1 ? true : false}>
                                <Link to="/admin/suppliers" className={`${window.location.href.indexOf("/admin/suppliers") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Suppliers</Link>
                            </MenuItem>



                            <SubMenu
                                title="Product"
                                icon={<FaList />}
                                className="font-Poppins"
                                active={activeNav("/admin/suppliers")}
                            >

                                <MenuItem icon={<FaList />} active={window.location.href.indexOf("/admin/categories") !== -1 ? true : false}>
                                    <Link to="/admin/categories" className={`${window.location.href.indexOf("/admin/categories") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Category</Link>
                                </MenuItem>
                                <MenuItem icon={<SiBrandfolder />} active={window.location.href.indexOf("/admin/brands") !== -1 ? true : false}>
                                    <Link to="/admin/brands" className={`${window.location.href.indexOf("/admin/brands") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Brands</Link>
                                </MenuItem>
                                <MenuItem icon={<RiListSettingsFill />} active={window.location.href.indexOf("/admin/attributes") !== -1 ? true : false}>
                                    <Link to="/admin/attributes" className={`${window.location.href.indexOf("/admin/attributes") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Attributes</Link>
                                </MenuItem>
                                <MenuItem icon={<RiPencilLine />} active={window.location.href.indexOf("/admin/products-adding") !== -1 ? true : false}>
                                    <Link to="/admin/products-adding" className={`${window.location.href.indexOf("/admin/products-adding") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Add Product</Link>
                                </MenuItem>
                                <MenuItem icon={<FaList />} active={window.location.href.indexOf("/admin/all-products") !== -1 ? true : false}>
                                    <Link to="/admin/all-products" className={`${window.location.href.indexOf("/admin/all-products") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>All Products</Link>
                                </MenuItem>

                            </SubMenu>

                            <MenuItem icon={<i className="fas fa-users"></i>} active={window.location.href.indexOf("/admin/customers") !== -1 ? true : false}>
                                <Link to="/admin/customers" className={`${window.location.href.indexOf("/admin/customers") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Customers</Link>
                            </MenuItem>

                            <MenuItem icon={<span className="fas fa-user-cog"></span>} active={window.location.href.indexOf("/admin/users") !== -1 ? true : false}>
                                <Link to="/admin/users" className={`${window.location.href.indexOf("/admin/users") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>System User</Link>
                            </MenuItem>

                            <SubMenu
                                title="Order History"
                                icon={<i className="fas fa-shopping-cart"></i>}
                                className="font-Poppins"
                                active={activeNav("/admin/suppliers")}
                            >

                                <MenuItem icon={<i className="fas fa-list"></i>} active={window.location.href.indexOf("/admin/orders") !== -1 ? true : false}>
                                    <Link to="/admin/orders" className={`${window.location.href.indexOf("/admin/orders") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>All Orders</Link>
                                </MenuItem>

                                <MenuItem icon={<i className="fas fa-spinner"></i>} active={window.location.href.indexOf("/admin/orders/Processing") !== -1 ? true : false}>
                                    <Link to="/admin/orders/Processing" className={`${window.location.href.indexOf("/admin/orders/Processing") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Processing</Link>
                                </MenuItem>

                                <MenuItem icon={<i className="fas fa-clipboard-check"></i>} active={window.location.href.indexOf("/admin/orders/Approve") !== -1 ? true : false}>
                                    <Link to="/admin/orders/Approve" className={`${window.location.href.indexOf("/admin/orders/Approve") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Approved</Link>
                                </MenuItem>

                                <MenuItem icon={<i className="fas fa-shipping-fast"></i>} active={window.location.href.indexOf("/admin/orders/On%20Shipping") !== -1 ? true : false}>
                                    <Link to="/admin/orders/On Shipping" className={`${window.location.href.indexOf("/admin/orders/On%20Shipping") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>On Shipping</Link>
                                </MenuItem>
                                <MenuItem icon={<i className="fas fa-people-carry"></i>} active={window.location.href.indexOf("/admin/orders/Shipped") !== -1 ? true : false}>
                                    <Link to="/admin/orders/Shipped" className={`${window.location.href.indexOf("/admin/orders/Shipped") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Shipped</Link>
                                </MenuItem>
                                <MenuItem icon={<i className="far fa-calendar-times"></i>} active={window.location.href.indexOf("/admin/orders/Cancelled") !== -1 ? true : false}>
                                    <Link to="/admin/orders/Cancelled" className={`${window.location.href.indexOf("/admin/orders/Cancelled") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Cancelled</Link>
                                </MenuItem>
                                <MenuItem icon={<i className="fas fa-undo-alt"></i>} active={window.location.href.indexOf("/admin/orders/Return") !== -1 ? true : false}>
                                    <Link to="/admin/orders/Return" className={`${window.location.href.indexOf("/admin/orders/Return") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Returned</Link>
                                </MenuItem>
                                <MenuItem icon={<i className="fas fa-check-double"></i>} active={window.location.href.indexOf("/admin/orders/Completed") !== -1 ? true : false}>
                                    <Link to="/admin/orders/Completed" className={`${window.location.href.indexOf("/admin/orders/Completed") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Completed</Link>
                                </MenuItem>

                            </SubMenu>
                            
                            <MenuItem icon={<i className="fas fa-dolly-flatbed"></i>} active={window.location.href.indexOf("/admin/shipping/class") !== -1 ? true : false}>
                                <Link to="/admin/shipping/class" className={`${window.location.href.indexOf("/admin/shipping/class") !== -1 ? "font-Poppins font-bold" : "font-Poppins"}`}>Shipping Classes</Link>
                            </MenuItem>


                        </Menu>
                    </SidebarContent>
                    {/* <SidebarFooter>
                        <Menu iconShape="square">
                            <MenuItem icon={<FiLogOut />}>Logout</MenuItem>
                        </Menu>
                    </SidebarFooter> */}
                </ProSidebar>
            </div>
        </>
    );
};

export default Header;
